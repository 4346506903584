export const environment = {
    name: "prd",
    production: true,
    rootAuthnUrl: "https://authn.spectrumreach.io",
    adminPermissionName: "adminUIAccess",
    cognito: {
        customProviderName: "ESSO",
        Auth: {
            region: "us-east-1",
            userPoolId: "us-east-1_C8Hxk4Itc",
            userPoolWebClientId: "4j99459p3plg3seaqs3rh42kni",
        },
        oauth: {
            domain: "srd-user-pool-prd.auth.us-east-1.amazoncognito.com",
            redirectSignIn: "https://admin.spectrumreach.io/",
            redirectSignOut: "https://admin.spectrumreach.io/",
            responseType: "token",
        }
    },
    allowCognitoAuth: true
};